.bar {
    fill: #7189FF;
    width: 25px;
    margin-left: 10px;
}

.axis path,
.axis line {
  fill: none;
  stroke: #bbb;
  shape-rendering: crispEdges;
}

.chart-container {
    width: 49%;
    display: inline-block;
    margin-left: 10px;
}

.chart-inner-text {
    position: absolute;
    top: -45px;
    right: 0;
    left: 0;
    bottom: 0;
    padding-top: 40%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
}

@media screen and (max-width: 768px) {
  .chart-container {
    width: 99%;
    padding: 10px;
  }

  .chart-inner-text {
    font-size: 14px;
    top: -20px;
  }
  
}

.chart-inner-text .first-row {
  margin-bottom: 0px;
}

.chart-inner-text .second-row {
  margin: 0 auto;
  max-width: 70%;
  overflow-wrap: break-word;
}

.chart-legend {
  float: right;
  width: 50%;
}

.chart-legend-block {
  border: black 1px solid;
  display: inline-block;
  height: 15px;
  margin: 8px 5px -2px;
  width: 15px;
}